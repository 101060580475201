.root {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;

  & > * {
    flex: 1 0 auto;
    max-width: 100%;
    min-width: fit-content;
    width: 1%;
  }
}

.singleItemRow {
  padding-right: 24px;

  & > * {
    max-width: 50%;
  }
}

.alignStart {
  align-items: flex-start;
}

.alignEnd {
  align-items: flex-end;
}
